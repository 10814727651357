<template>
  <div class="col-md-12 mt-5">
    <h1 class="text-center">Page not found</h1>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound'
}
</script>

<style scoped>

</style>